<template>
  <div class="container" v-loading="loading2" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-popover placement="right" width="800" v-model="searchShow">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
              <el-form-item label="单位名称">
                <el-input v-model="searchForm.unitName" autocomplete="off" size="small"
                          placeholder="请输入单位名称" clearable></el-input>
              </el-form-item>
              <el-form-item label="行政区域">
                <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList"
                             :props="{ checkStrictly: true }" clearable></el-cascader>
              </el-form-item>
              <el-form-item label="监管类别" v-if="typeId===0">
                <el-select v-model="searchForm.typeId" placeholder="请选择监管类别" clearable size="small" style="width:145px">
                  <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属行业">
                <el-select v-model="searchForm.industryId" placeholder="请选择所属行业" clearable size="small"
                           style="width:145px">
                  <el-option v-for="item in industryList" :key="item.id" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间">
                <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" size="small">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="是否同步">
                <el-select v-model="searchForm.syncFlag" placeholder="请选择" clearable size="small" style="width:100px">
                  <el-option label="已同步" :value="1"></el-option>
                  <el-option label="未同步" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否定位" v-if="typeId===1">
                <el-select v-model="searchForm.locationStatus" placeholder="请选择" clearable size="small"
                           style="width:100px">
                  <el-option label="已定位" :value="1"></el-option>
                  <el-option label="未定位" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否拍照" v-if="typeId===1">
                <el-select v-model="searchForm.photoStatus" placeholder="请选择" clearable size="small"
                           style="width:100px">
                  <el-option label="已拍照" :value="1"></el-option>
                  <el-option label="未拍照" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否安装">
                <el-select v-model="searchForm.installDeviceStatus" placeholder="请选择" clearable size="small"
                           style="width:100px">
                  <el-option label="已安装" :value="1"></el-option>
                  <el-option label="未安装" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" @click="searchShow = false">取消</el-button>
              <el-button type="primary" size="mini" @click="initTable">查询</el-button>
            </div>
            <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')"
                     v-show="btnArray.indexOf('unit:add')!==-1||isAdmin===1">新增单位
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-tooltip content="导出单位" placement="top" effect="dark">
            <el-button type="warning" size="small" icon="el-icon-download" @click="exportExcel">导出单位</el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <el-tooltip content="省平台单位查询" placement="top" effect="dark">
            <el-button size="small" icon="el-icon-search" @click="open('province')">省平台单位查询</el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <div class="content" v-loading="loading1">
      <el-table :data="companyList" stripe style="width: 100%" height="100%" row-key="id"
                @selection-change="handleSelectionChange" ref="table">
        <!-- <el-table-column type="selection" width="50" reserve-selection>
        </el-table-column> -->
        <el-table-column type="expand" width="50">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="单位名称" :label-width="formLabelWidth">
                    <span>{{ props.row.unitName }}</span>
                  </el-form-item>
                  <el-form-item label="地址" :label-width="formLabelWidth">
                    <span>{{ props.row.address }}</span>
                  </el-form-item>
                  <el-form-item label="行政区域" :label-width="formLabelWidth">
                    <span>{{ props.row.areaName }}</span>
                  </el-form-item>
                  <el-form-item label="法人" :label-width="formLabelWidth">
                    <span>{{ props.row.legalName }}</span>
                  </el-form-item>
                  <el-form-item label="法人电话" :label-width="formLabelWidth">
                    <span>{{ props.row.legalPhone }}</span>
                  </el-form-item>
                  <el-form-item label="法人身份证" :label-width="formLabelWidth">
                    <span>{{ props.row.legalIdCard }}</span>
                  </el-form-item>
                  <el-form-item label="经纬度" :label-width="formLabelWidth">
                    <span>{{ props.row.lng }} # {{ props.row.lat }}</span>
                    <el-button type="text" size="default"
                               @click="open('location',{lng:props.row.lng,lat:props.row.lat})"
                               icon="el-icon-location"></el-button>
                  </el-form-item>
                  <el-form-item label="智赣主键" :label-width="formLabelWidth">
                    <span>{{ props.row.outerSystemId }}</span>
                  </el-form-item>
                  <el-form-item label="单位图片" :label-width="formLabelWidth">
                    <el-image :src="props.row.unitImage" fit="fill" :lazy="true"
                              :preview-src-list="[props.row.unitImage]"></el-image>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="单位ID" :label-width="formLabelWidth">
                    <span>{{ props.row.id }}</span>
                  </el-form-item>
                  <el-form-item label="uuid" :label-width="formLabelWidth">
                    <span>{{ props.row.uuid }}</span>
                  </el-form-item>
                  <el-form-item label="监管类别" :label-width="formLabelWidth">
                    <span>{{ props.row.unitTypeName }}</span>
                  </el-form-item>
                  <el-form-item label="组织机构代码" :label-width="formLabelWidth">
                    <span>{{ props.row.organizationCode }}</span>
                  </el-form-item>
                  <el-form-item label="单位编码" :label-width="formLabelWidth">
                    <span>{{ props.row.unitCoding }}</span>
                  </el-form-item>
                  <el-form-item label="单位性质" :label-width="formLabelWidth">
                    <span>{{ props.row.unitNatureName }}</span>
                  </el-form-item>
                  <el-form-item label="所属行业" :label-width="formLabelWidth">
                    <span>{{ props.row.industryName }}</span>
                  </el-form-item>
                  <el-form-item label="单位介绍" :label-width="formLabelWidth">
                    <span>{{ props.row.unitCoding }}</span>
                  </el-form-item>
                  <el-form-item label="创建时间" :label-width="formLabelWidth">
                    <span>{{ props.row.createTime|timeFormat }}</span>
                  </el-form-item>
                  <el-form-item label="更新时间" :label-width="formLabelWidth">
                    <span>{{ props.row.updateTime|timeFormat }}</span>
                  </el-form-item>
                  <el-form-item label="已安装设备" :label-width="formLabelWidth">
                    <el-button type="primary" size="mini" @click="open('device',props.row.id)" icon="el-icon-search">
                      查看设备
                    </el-button>
                  </el-form-item>
                  <el-form-item label="主建筑3D图" :label-width="formLabelWidth">
                    <el-button type="success" size="mini" @click="open('3D',props.row.id)" icon="el-icon-view">查看3D图
                    </el-button>
                  </el-form-item>
                  <el-form-item label="室外水源" :label-width="formLabelWidth">
                    <el-button type="warning" size="mini" @click="open('position',props.row)" icon="el-icon-location">
                      查看位置
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="open('unit',scope.row)">{{ scope.row.unitName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="legalName" label="法人" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip width="120">
        </el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="unitTypeName" label="监管类别" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="industryName" label="所属行业" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="经纬度" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.lng.toFixed(3) }}#{{ scope.row.lat.toFixed(3) }}</span>
            <el-button type="text" icon="el-icon-location" size="default"
                       @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="equipmentCount" label="已安装设备" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="sysFlag" label="同步状态" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-show="scope.row.syncFlag!==1">未同步</el-tag>
            <el-tag type="success" size="small" v-show="scope.row.syncFlag===1">已同步</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-popover placement="top" trigger="click">
              <el-tooltip class="item" effect="dark" content="修改" placement="top">
                <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle
                           v-role="'unit:edit'"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top">
                <el-button type="danger" @click="deleteCompany(scope.row.id)" size="mini" icon="el-icon-delete-solid"
                           circle v-role="'unit:del'"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="单位联动管理" placement="top">
                <el-button type="primary" @click="open('link',scope.row.id)" size="mini" icon="el-icon-paperclip"
                           circle></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="单位同步" placement="top">
                <el-button type="success" @click="setUnitSync(scope.row.id)" size="mini" icon="el-icon-refresh" circle
                           v-show="scope.row.syncFlag!==1"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="强制同步" placement="top">
                <el-button type="danger" @click="setUnitSync(scope.row.id)" size="mini" icon="el-icon-refresh" circle
                           v-show="scope.row.syncFlag===1"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="设备迁移" placement="top">
                <el-button @click="open('transfer',scope.row.id)" size="mini" icon="el-icon-right" circle
                           v-show="btnArray.indexOf('unit:unitDeviceMove')!==-1||isAdmin===1"></el-button>
              </el-tooltip>
              <el-button slot="reference" size="mini" type="primary" icon="el-icon-setting" circle></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                     layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
      <div class="footer-btn" v-show="deleteList.length>0">
        <el-tooltip content="批量删除" placement="top" effect="dark">
          <el-button type="danger" round size="small" icon="el-icon-delete" @click="deleteMuti"></el-button>
        </el-tooltip>
        <el-tooltip content="清空" placement="top" effect="dark">
          <el-button size="small" round icon="el-icon-close" @click="clearSelection"></el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- 单位弹窗 -->
    <el-dialog :title="optFlag?'添加单位':'修改单位'" :visible.sync="unitShow" width="55%" @closed="clear" top="3%" v-dialogDrag
               :close-on-click-modal="false">
      <el-form :model="companyForm" ref="companyForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="单位名称" prop="unitName" :label-width="formLabelWidth2">
              <el-input v-model="companyForm.unitName" autocomplete="off" placeholder="请输入单位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
              <el-input v-model="companyForm.address" autocomplete="off" placeholder="请输入单位地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="行政区域" :label-width="formLabelWidth2">
              <el-cascader :disabled="isLoad" v-model="companyForm.areaCodes" placeholder="请选择该单位的行政区域"
                           :options="areaList" :props="{ checkStrictly: true }" clearable
                           style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="包含系统" prop="containSystems" :label-width="formLabelWidth">
              <el-select v-model="companyForm.containSystems" placeholder="请选择该单位包含的系统" clearable multiple filterable>
                <el-option v-for="item in systemList" :key="item.value" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防安全负责人" prop="fireSafePerson" :label-width="formLabelWidth2">
              <el-input v-model="companyForm.fireSafePerson" autocomplete="off" placeholder="请输入消防安全负责人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="负责人电话" prop="fireSafePersonTel" :label-width="formLabelWidth">
              <el-input v-model="companyForm.fireSafePersonTel" autocomplete="off" placeholder="请输入负责人电话" maxlength="11"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="经纬度" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lng">
                    <el-input v-model.number="companyForm.lng" autocomplete="off" placeholder="经度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lat">
                    <el-input v-model.number="companyForm.lat" autocomplete="off" placeholder="纬度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3" :offset="0" style="padding-left:0">
                  <el-form-item>
                    <el-tooltip class="item" effect="dark" content="定位" placement="top">
                      <el-button type="text" style="font-size:16px" size="default" @click="open('map')"
                                 icon="el-icon-location"></el-button>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="所属行业" prop="industryId" :label-width="formLabelWidth">
              <el-select v-model="companyForm.industryId" placeholder="请选择所属行业" clearable filterable>
                <el-option v-for="item in industryList" :key="item.value" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="法人姓名" prop="legalName" :label-width="formLabelWidth2">
              <el-input v-model="companyForm.legalName" autocomplete="off" placeholder="请输入法人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="法人电话" prop="legalPhone" :label-width="formLabelWidth">
              <el-input v-model="companyForm.legalPhone" autocomplete="off" placeholder="请输入法人电话" maxlength="11"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="法人身份证" prop="legalIdCard" :label-width="formLabelWidth2">
              <el-input v-model="companyForm.legalIdCard" autocomplete="off" placeholder="请输入法人身份证"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="监管等级" prop="monitorLevel" :label-width="formLabelWidth">
              <el-select v-model="companyForm.monitorLevel" placeholder="请选择监管等级" clearable filterable>
                <el-option v-for="item in monitorList" :key="item.value" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="单位性质" prop="natureId" :label-width="formLabelWidth2">
              <el-select v-model="companyForm.natureId" placeholder="请选择单位性质" clearable>
                <el-option v-for="item in natureList" :key="item.value" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="联网状态" prop="networkStatus" :label-width="formLabelWidth">
              <el-select v-model="companyForm.networkStatus" placeholder="请选择联网状态" clearable>
                <el-option label="未联网" :value="0"></el-option>
                <el-option label="已联网" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="组织机构代码" prop="organizationCode" :label-width="formLabelWidth2">
              <div style="display: flex">
                <el-input style="flex: 1;margin-right: 4px" v-model="companyForm.organizationCode" autocomplete="off"
                          placeholder="请输入组织机构代码"></el-input>
                <el-button type="info" @click="generateRandomOrganizationCode">生成</el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="监管类别" prop="typeId" :label-width="formLabelWidth2">
              <el-select v-model="companyForm.typeId" placeholder="请选择监管类别" clearable>
                <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="单位类别" prop="smallTypeId" :label-width="formLabelWidth">
              <el-select v-model="companyForm.smallTypeId" placeholder="请选择单位类别" clearable>
                <el-option
                  v-for="item in smallTypeListFilter"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="单位编码" prop="unitCoding" :label-width="formLabelWidth">
              <el-input v-model="companyForm.unitCoding" autocomplete="off" placeholder="请输入单位编码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防主管部门" :label-width="formLabelWidth2" prop="fireManageId">
              <!-- <el-autocomplete class="inline-input" v-model="deviceForm.unitName" value-key="unitName" :fetch-suggestions="querySearch" placeholder="请输入所在单位" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete> -->
              <el-select v-model="companyForm.fireManageId" ref="selectSector" placeholder="请选择消防机构" clearable
                         filterable remote :remote-method="remoteMethod">
                <el-option v-for="(item,i) in sectorOptions" :key="i" :label="item.companyName" :value="item.id">
                  <span style="float: left">{{ item.companyName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="智赣主键" prop="outerSystemId" :label-width="formLabelWidth">
              <el-input v-model="companyForm.outerSystemId" autocomplete="off" placeholder="请输入智赣主键"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="行业主管部门" prop="industryDeptId" :label-width="formLabelWidth2">
              <el-select v-model="companyForm.industryDeptId" placeholder="请选择行业主管部门" clearable>
                <el-option v-for="item in industryDeptList" :key="item.id" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="单位基本概述" prop="unitIntroduce" :label-width="formLabelWidth2">
              <el-input type="textarea" v-model="companyForm.unitIntroduce" autocomplete="off"
                        placeholder="请输入单位基本概述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="单位主照片" prop="unitImage" :label-width="formLabelWidth2">
              <el-upload ref="upload" :file-list="fileList" :headers="{Authorization:token}"
                         action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getUnitImage"
                         :on-error="error" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog title="单位图片" :visible.sync="dialogVisible" :append-to-body="true">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="unitShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addCompany" v-if="optFlag">添 加</el-button>
        <el-button size="small" type="primary" @click="updateCompany" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 地图 -->
    <el-dialog class="dialog" :visible.sync="mapShow" width="50%" top="5%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom"
                   ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
                          :autoLocation="true"></bm-geolocation>
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
      <div class="dialog-footer" v-show="optFlag2">
        <el-button size="small" @click="mapShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="getPosition">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表 -->
    <el-dialog title="安装设备列表" :visible.sync="deviceShow" width="80%" top="3%" v-dialogDrag
               :close-on-click-modal="false">
      <div class="container noangle" v-loading="loading">
        <div class="header">
          <el-form :inline="true" :model="searchDeviceForm" class="demo-form-inline">
            <el-form-item label="设备特征码">
              <el-input v-model="searchDeviceForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入单位名称"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-select v-model="searchDeviceForm.typeId" placeholder="请选择设备类型" size="small" clearable>
                <el-option v-for="(item,i) in deviceTypeList" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="initDevice">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="deviceList" stripe style="width: 100%" height="500px">
            <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-show="scope.row.typeId!==8&&scope.row.typeId!==14">{{ scope.row.typeName }}</span>
                <el-button type="text" size="default" @click="open('video',scope.row)"
                           v-show="scope.row.typeId===8||scope.row.typeId===14">{{ scope.row.typeName }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="installName" label="安装建筑物" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="stationCode" label="基站特征码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="value" label="当前数值" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-show="scope.row.typeId===3||scope.row.typeId===4">{{ scope.row.value }}mpa</span>
                <span v-show="scope.row.typeId===5||scope.row.typeId===6">{{ scope.row.value }}M</span>
              </template>
            </el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.installTime|timeFormat }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleCurrentChange2" :current-page.sync="devicePage" :page-size="10"
                         layout="total, prev, pager, next, jumper" :total="deviceTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 主建筑3D图 -->
    <el-dialog title="主建筑3D图" :visible.sync="threeDShow" width="40%" class="three3D-dialog" v-dialogDrag
               :close-on-click-modal="false">
      <div class="threeD-list">
        <div class="list-left">
          <video src="../../assets/img/3D.mp4" autoplay muted loop></video>
        </div>
        <div class="list-right">
          <div class="list-item" v-for="(item,i) in floorList" :key="i" @click="open('floor',i)">
            <span><i class="el-icon-office-building" style="margin-right:5px"></i>{{ item }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 摄像头 -->
    <el-dialog title="视频摄像头" :visible.sync="videoShow" width="30%" v-dialogDrag :close-on-click-modal="false">
      <hik-vision :cameraCode="cameraCode" v-if="videoShow" />
    </el-dialog>
    <!-- 地图 -->
    <el-dialog class="dialog" :visible.sync="positionShow" width="50%" top="5%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom"
                   ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler2">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
        </baidu-map>
      </div>
    </el-dialog>
    <!-- 主建筑3D图 -->
    <el-dialog :title="floorTitle" :visible.sync="floorShow" width="40%" class="three3D-dialog" v-dialogDrag
               :close-on-click-modal="false">
      <el-image :src="floorPic" fit="fill"></el-image>
    </el-dialog>
    <!-- 单位详情窗口 -->
    <el-dialog title="单位详情" :visible.sync="infoShow" fullscreen class="unit-info" @closed="close">
      <el-header class="unit-header">
        <div class="header-menu">
          <el-menu class="el-menu-demo" mode="horizontal" :router="true" :default-active="$route.path">
            <template v-for="(item,i) in menu">
              <el-submenu :key="i" v-if="item.children" :index="item.id">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span slot="title">{{ item.text }}</span>
                </template>
                <template v-for="(subitem,j) in item.children">
                  <!-- <el-submenu :key="j" v-if="subitem.children" :index="subitem.id">
                    <template slot="title">
                      <i :class="subitem.icon"></i>
                      <span slot="title">{{subitem.text}}</span>
                    </template>
                    <el-menu-item :key="n" :index="childItem.path" v-for="(childItem,n) in subitem.children">
                      <i :class="childItem.icon"></i>
                      <span slot="title">{{childItem.text}}</span>
                    </el-menu-item>
                  </el-submenu> -->
                  <el-menu-item :key="j" :index="subitem.path">
                    <i :class="subitem.icon"></i>
                    <span slot="title">{{ subitem.text }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :key="i" :index="item.path" v-else>
                <i :class="item.icon"></i>
                <span slot="title">{{ item.text }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </el-header>
      <main class="unit-main">
        <transition name="el-fade-in-linear">
          <router-view :companyId="companyId" :isAdmin="false"></router-view>
        </transition>
      </main>
    </el-dialog>
    <!-- 单位联动弹窗 -->
    <el-dialog title="单位联动" :visible.sync="linkShow" width="70%" top="2%">
      <div class="header">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-button type="success" size="small" icon="el-icon-plus" @click="open('addLink')">新增联动单位</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="content card-box">
        <el-card shadow="always" :body-style="{ padding: '20px' }" v-for="(item,i) in linkList" :key="i">
          <div slot="header" style="text-align:left">
            {{ item.relateUnitName }}
            <div style="float: right;">
              <el-tooltip content="关联单位信息" placement="top" effect="dark">
                <el-button style="padding: 3px 0;color:#409EFF" type="text" @click="open('unit',item.relateUnitId)"
                           icon="el-icon-search"></el-button>
              </el-tooltip>
              <el-tooltip content="单位设备" placement="top" effect="dark">
                <el-button style="padding: 3px 0;color:#E6A23C" type="text" @click="open('device',item.relateUnitId)"
                           icon="el-icon-setting"></el-button>
              </el-tooltip>
              <el-tooltip content="删除关联" placement="top" effect="dark">
                <el-button style=" padding: 3px 0;color:#F56C6C" type="text" @click="delLink(item.id)"
                           icon="el-icon-delete"></el-button>
              </el-tooltip>
            </div>
          </div>
          <div>
            <el-tag type="primary" size="normal">设备数量:{{ item.equipmentCount }}</el-tag>
            <el-tag type="primary" size="normal">NFC巡检:{{ item.nfcCount }}</el-tag>
            <el-tag type="primary" size="normal">设备维护保养报告:{{ item.deviceProtectReportCount }}</el-tag>
            <el-tag type="primary" size="normal">消防控制室值班记录:{{ item.fireControlDutyRecordCount }}</el-tag>
            <el-tag type="primary" size="normal">责令整改:{{ item.rectifyNoticeCount }}</el-tag>
            <el-tag type="primary" size="normal">防火巡检:{{ item.firePatrolCount }}</el-tag>
          </div>
        </el-card>
      </div>
    </el-dialog>
    <!-- 单位联动添加弹窗 -->
    <el-dialog v-if="linkAddShow" :visible.sync="linkAddShow" width="80%" top="3%" :close-on-click-modal="false"
               class="linkMap">
      <div class="container_content">
        <div class="left_box" v-show="showLeft">
          <div class="search_box">
            <div>
              <div class="title"> 单位列表</div>
              <el-input v-model="keyword" placeholder="请输入名称进行搜索" clearable size="small"
                        @input="initNotLink"></el-input>
            </div>
          </div>
          <div>
            <el-table :data="notLinkList" height="100%">
              <el-table-column prop="unitName" label="名称" show-overflow-tooltip></el-table-column>
              <el-table-column label="定位" width="60px">
                <template slot-scope="scope">
                  <el-button type="text" size="medium" @click="pointTo(scope.row, 'localtion')" icon="el-icon-location"
                             style="font-size:18px"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-pagination :pager-count="5" small @current-change="handleNotLinkChange" :current-page.sync="notLinkPage"
                           :page-size="100" layout="total,prev, pager, next" :total="notLinkTotal">
            </el-pagination>
          </div>
        </div>
        <div class="right_box" v-loading="linkLoading">
          <div class="map_box">
            <baidu-map class="bmView" :scroll-wheel-zoom="true" @ready="handler2"></baidu-map>
          </div>
          <div class="left_button" @click="showLeft = !showLeft">
            <i class="el-icon-d-arrow-left" v-show="showLeft"></i>
            <i class="el-icon-d-arrow-right" v-show="!showLeft"></i>
          </div>
          <transition name="el-zoom-in-center">
            <div class="link-tag" v-show="unitListIds.length>0">
              <div class="link-title">已选单位</div>
              <div class="tag-list">
                <el-tag type="success" size="normal" closable @close="delWillLink(item)" v-for="(item,i) in unitListIds"
                        :key="i">{{ item.unitName }}
                </el-tag>
              </div>
              <div class="link-btn">
                <el-button type="primary" round size="mini" @click="addLink">添 加</el-button>
              </div>
            </div>
          </transition>
          <div class="close" @click="linkAddShow=false"><i class="el-icon-close"></i></div>
        </div>
      </div>
    </el-dialog>
    <!-- 省平台单位查询 -->
    <el-dialog title="省平台单位查询" :visible.sync="provinceUnitShow" width="60%" top="3%" @close="clearProvinceUnit">
      <div class="header">
        <el-form :inline="true" class="demo-form-inline">
          <el-form :inline="true" :model="searchUnit" class="demo-form-inline">
            <el-form-item label="单位名称">
              <el-input v-model="searchUnit.companyName" autocomplete="off" size="small" placeholder="请输入"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="组织机构代码">
              <el-input v-model="searchUnit.orgLicense" autocomplete="off" size="small" placeholder="请输入"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="行政区域">
              <div class="area">
                <el-button type="primary" size="small" icon="el-icon-map-location" @click="open('area')">
                  {{ areaName }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" icon="el-icon-search" @click="initProvince">查询</el-button>
            </el-form-item>
          </el-form>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table :data="provinceUnitList" stripe style="width: 100%" height="500" v-loading="provinceUnitLoading"
                  highlight-current-row @current-change="chooseProvinceUnit">
          <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="companyName" label="单位名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="address" label="单位地址" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="corporator" label="法人" show-overflow-tooltip width="70">
          </el-table-column>
          <el-table-column prop="corporatorTel" label="法人电话" show-overflow-tooltip width="130">
          </el-table-column>
          <el-table-column prop="orgLicense" label="组织机构代码" show-overflow-tooltip width="180">
          </el-table-column>
          <el-table-column prop="companyCode" label="智赣主键" show-overflow-tooltip width="180">
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <div class="footer-btn" v-show="provinceBtnFlag">
          <el-button type="primary" round size="small" icon="el-icon-plus" @click="addProvinceUnit">添加</el-button>
          <el-button size="small" round icon="el-icon-close" @click="provinceBtnFlag=false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 区域选择 -->
    <el-dialog title="区域选择" :visible.sync="areaShow" width="30%">
      <el-tree ref="tree" :data="provinceAreaList" node-key="id" :props="{label:'areaName'}" empty-text="暂无数据"
               @node-click="checkArea"></el-tree>
      <span slot="footer">
        <el-button @click="areaShow = false">取 消</el-button>
        <el-button type="primary" @click="changeArea">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设备迁移选择 -->
    <el-dialog title="设备迁移" :visible.sync="transferShow" width="60%" top="3%">
      <div class="header">
        <el-form :inline="true" class="demo-form-inline">
          <el-form :inline="true" :model="searchUnit" class="demo-form-inline">
            <el-form-item label="单位名称">
              <el-input v-model="searchTransfer.unitName" autocomplete="off" size="small" placeholder="请输入"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="行政区域">
              <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList"
                           :props="{ checkStrictly: true }" clearable></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" icon="el-icon-search" @click="initTansfer">查询</el-button>
            </el-form-item>
          </el-form>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table :data="transferUnitList" stripe style="width: 100%" height="500" v-loading="transferLoading"
                  highlight-current-row @current-change="chooseUnit">
          <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip width="100">
          </el-table-column>
          <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="legalName" label="法人" show-overflow-tooltip width="80">
          </el-table-column>
          <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip width="120">
          </el-table-column>
          <el-table-column prop="address" label="地址" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="unitTypeName" label="监管类别" show-overflow-tooltip width="100">
          </el-table-column>
          <el-table-column prop="industryName" label="所属行业" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <div class="footer-btn" v-show="transferBtnFlag">
          <el-button type="primary" round size="small" icon="el-icon-plus" @click="transferDevice">添加</el-button>
          <el-button size="small" round icon="el-icon-close" @click="transferBtnFlag=false">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <company-detail-dialog ref="CompanyDetailDialog"/>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import HikVision from '../../components/HikVision.vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import CompanyDetailDialog from "./dialogs/CompanyDetailDialog"
export default {
  components: {
    BaiduMap,
    BmView,
    BmGeolocation,
    BmMarker,
    HikVision,
    CompanyDetailDialog
  },
  computed: {
    // typeId===3  九小场所
    smallTypeListFilter() {
      if (!!!this.companyForm.typeId || this.companyForm.typeId !== 3) return this.smallTypeList
      return this.smallTypeList.filter(v => v.typeId === 3)
    }
  },
  data() {
    return {
      // 页面路由
      path: '',
      fileList: [],
      areaCodes: [],
      transferAreaCodes: [], // 迁移设备单位查询行政区域
      dialogImageUrl: '',
      dialogVisible: false,
      isLoad: false,
      // 经纬度
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      map: null,
      areaName: '',
      areaCode: '',
      formLabelWidth: '120px',
      formLabelWidth2: '110px',
      currentPage: 1,
      devicePage: 1,
      notLinkPage: 1,
      total: 0,
      deviceTotal: 0,
      notLinkTotal: 0,
      companyList: [],
      areaList: [], // 行政区域列表
      provinceAreaList: [], // 省平台区域列表
      industryList: [], // 所属行业列表
      systemList: [], // 系统列表
      natureList: [], // 单位性质
      monitorList: [], // 监管等级列表
      smallTypeList: [], // 小类型
      typeList: [], // 类型
      deviceTypeList: [], // 设备类型
      deleteList: [],
      linkList: [], // 关联单位列表
      notLinkList: [], // 未关联列表
      unitListIds: [], // 选中关联单位
      provinceUnitList: [], // 省平台单位
      transferUnitList: [], // 迁移设备单位
      industryDeptList: [], // 行业主管部门
      sectorOptions: [],
      floorList: ['一楼', '二楼'],
      floorPicList: [require('../../assets/img/first-floor.jpg'), require('../../assets/img/second-floor.jpeg')],
      floorPic: '',
      companyId: 0, // 单位ID
      typeId: '',
      // 表单数据
      companyForm: {
        uniName: '',
        address: '',
        areaCode: '',
        areaCodes: [],
        containSystems: '',
        fireSafePerson: '',
        fireSafePersonTel: '',
        industryId: '',
        lat: '',
        legalIdCard: '',
        legalName: '',
        legalPhone: '',
        lng: '',
        monitorLevel: '',
        natureId: '',
        networkStatus: '',
        organizationCode: '',
        smallTypeId: '',
        typeId: '',
        unitCoding: '',
        unitIntroduce: '',
        unitImage: null
      }, // 建筑表单
      companyFormCopy: {},
      // 省平台单位
      provinceUnit: {},
      transferUnitId: {},
      deviceList: [],
      rules: {
        typeId: [
          {required: true, message: '请选择监管类别', trigger: 'change'}
        ],
        unitName: [
          {required: true, message: '请输入单位名称', trigger: 'blur'}
        ],
        monitorLevel: [
          {required: true, message: '请选择监管等级', trigger: 'change'}
        ],
        smallTypeId: [
          {required: true, message: '请选择单位类别', trigger: 'change'}
        ],
        legalName: [
          {required: true, message: '请输入法人姓名', trigger: 'blur'}
        ],
        organizationCode: [
          {required: true, message: '请输入组织机构代码', trigger: 'blur'}
        ],
        industryDeptId: [
          {required: true, message: '请选择行业主管部门', trigger: 'change'}
        ],
        fireManageId: [
          {required: true, message: '请选择消防主管部门', trigger: 'change'}
        ],
        legalPhone: [
          {required: true, message: '请输入法人电话号码', trigger: 'blur'},
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '格式不正确', trigger: 'blur'}
        ],
        legalIdCard: [
          {
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '身份证格式错误',
            trigger: 'blur'
          }
        ],
        fireSafePersonTel: [
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '格式不正确', trigger: 'blur'}
        ]
      },
      searchForm: {
        areaCodes: [],
        date: []
      },
      searchDeviceForm: {
        equipmentCode: '',
        typeId: ''
      },
      searchUnit: {}, // 省平台单位查询
      searchTransfer: {}, // 迁移设备单位查询
      unitShow: false, // 表单
      mapShow: false, // 地图
      deviceShow: false, // 列表
      optFlag: false, // 单位表单添加修改flag
      optFlag2: false, // 添加修改flag
      provinceBtnFlag: false, // 省平台单位按钮
      transferBtnFlag: false, // 设备迁移按钮
      searchShow: false,
      threeDShow: false,
      videoShow: false,
      positionShow: false,
      floorShow: false,
      infoShow: false,
      linkShow: false,
      linkAddShow: false,
      provinceUnitShow: false,
      areaShow: false,
      transferShow: false,
      floorTitle: '',
      token: '',
      loading: false,
      loading1: false,
      loading2: false,
      linkLoading: false,
      provinceUnitLoading: false,
      transferLoading: false,
      showLeft: true,
      cameraCode: '',
      point: null,
      markerIcon: null,
      selfMarker: null,
      label: null,
      keyword: '', // 未关联单位查询
      menu: [{
        id: '9001',
        text: '单位信息档案',
        path: '/home/companyinfo/info/base',
        icon: 'el-icon-folder-opened'
      }, {
        id: '9002',
        text: '日常工作',
        icon: 'el-icon-date',
        children: [{
          id: '9012',
          text: 'NFC巡检',
          path: '/home/companyinfo/NFC',
          icon: 'el-icon-mobile-phone'
        }, {
          id: '9013',
          text: '防火巡检',
          path: '/home/companyinfo/inspect',
          icon: 'el-icon-map-location'
        }, {
          id: '9014',
          text: '消防控制室值班记录',
          path: '/home/companyinfo/dutyrecord',
          icon: 'el-icon-s-order'
        }, {
          id: '9015',
          text: '设施维护保养报告',
          path: '/home/companyinfo/devicereport',
          icon: 'el-icon-document'
        }, {
          id: '9016',
          text: '责令整改',
          path: '/home/companyinfo/correct',
          icon: 'el-icon-time'
        }]
      }, {
        id: '9003',
        text: '水源信息',
        path: '/home/companyinfo/water',
        icon: 'el-icon-s-tools'
      }, {
        id: '9004',
        text: '物联设备',
        path: '/home/companyinfo/device',
        icon: 'el-icon-cpu'
      }, {
        id: '9005',
        text: '告警信息',
        path: '/home/companyinfo/alarmInfo',
        icon: 'el-icon-alarm-clock'
      }],
      btnArray: [],
      isAdmin: ''
    }
  },
  watch: {
    '$route.path': 'changeRoute',
    linkAddShow() {
      if (!this.linkAddShow) {
        this.unitListIds = []
      }
    }
  },
  activated() {
    this.checkQuery()
  },
  created() {
    this.checkQuery()
    this.isAdmin = parseInt(sessionStorage.getItem('isAdmin'))
    this.btnArray = JSON.parse(sessionStorage.getItem('btnRole'))
    switch (parseInt(this.$route.params.id)) {
      case 0:
        this.typeId = parseInt(this.$route.params.id)
        this.path = '/home/company/0'
        break
      case 1:
        this.typeId = parseInt(this.$route.params.id)
        this.path = `/home/company/${this.typeId}`
        break
      case 2:
        this.typeId = parseInt(this.$route.params.id)
        this.path = `/home/company/${this.typeId}`
        break
      case 3:
        this.typeId = parseInt(this.$route.params.id)
        this.path = `/home/company/${this.typeId}`
        break
      case 4:
        this.typeId = parseInt(this.$route.params.id)
        this.path = `/home/company/${this.typeId}`
        break
      case 99:
        this.typeId = parseInt(this.$route.params.id)
        this.path = `/home/company/${this.typeId}`
        break
    }
    this.token = sessionStorage.getItem('token')
    this.loading1 = true
    this.initTable()
    this.getAreaList()
    this.getNatureList()
    this.getIndustryList()
    this.getTypeList()
    this.getSmallTypeList()
    this.getMonitorList()
    this.getSystemList()
    this.getDeviceTypeList()
    this.getIndustryDeptList()
    this.companyFormCopy = JSON.stringify(this.companyForm)
    window.addWillLink = this.addWillLink
  },
  methods: {
    //自动展示info
    checkQuery() {
      const {companyId} = this.$route.query
      if (!!companyId) {
        this.open("unit", companyId)
      }
    },
    //生成随机组织机构代码
    generateRandomOrganizationCode() {
      this.$set(this.companyForm, 'organizationCode', uuidv4().replaceAll("-", "").substring(0, 14).toUpperCase() + "8888")
    },
    // 改变路由后变更类型
    changeRoute() {
      this.btnArray = JSON.parse(sessionStorage.getItem('btnRole'))
      const typeId = +this.$route.params.id
      this.typeId = typeId
      // if (typeId === 0) {
      //   this.path = '/home/company/0'
      // }
      // switch (parseInt(this.$route.params.id)) {
      //   case 0:
      //     this.typeId = parseInt(this.$route.params.id)
      //     this.path = '/home/company/0'
      //     break
      //   case 1:
      //     this.typeId = parseInt(this.$route.params.id)
      //     break
      //   case 2:
      //     this.typeId = parseInt(this.$route.params.id)
      //     break
      //   case 3:
      //     this.typeId = parseInt(this.$route.params.id)
      //     break
      //   case 4:
      //     this.typeId = parseInt(this.$route.params.id)
      //     break
      //   case 99:
      //     this.typeId = parseInt(this.$route.params.id)
      //     break
      // }
      if(typeId !==undefined){
        this.loading1 = true
      }
      this.initTable()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.initTable()
    },
    handleCurrentChange2(val) {
      this.devicePage = val
      this.initDevice()
    },
    handleNotLinkChange(val) {
      this.linkPage = val
      this.initNotLink()
    },
    // 初始化单位表格
    async initTable() {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.searchForm.typeId > 0) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.industryId > 0) {
        data.industryId = this.searchForm.industryId
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      if (this.typeId > 0) {
        data.typeId = this.typeId
      }
      data.locationStatus = this.searchForm.locationStatus
      data.photoStatus = this.searchForm.photoStatus
      data.installDeviceStatus = this.searchForm.installDeviceStatus
      data.syncFlag = this.searchForm.syncFlag
      data.pageNo = this.currentPage
      data.pageSize = 10
      // this.loading1 = true
      const {data: result} = await this.$axios.get('/unit/listWeb', {params: data})
      if (result.code === 200) {
        this.loading1 = false
        this.companyList = result.data.result
        this.total = result.data.total
      }
      else {
        this.loading1 = false
        this.$message.error(result.msg)
      }
    },
    // 初始化设备列表
    async initDevice() {
      const data = {}
      if (this.searchDeviceForm.typeId) {
        data.typeId = this.searchDeviceForm.typeId
      }
      if (this.searchDeviceForm.equipmentCode) {
        data.equipmentCode = this.searchDeviceForm.equipmentCode
      }
      data.pageNo = this.devicePage
      data.pageSize = 10
      data.unitId = this.companyId
      this.loading = true
      const {data: result} = await this.$axios.get('/equipment/list', {params: data})
      if (result.code === 200) {
        this.deviceList = result.data.result
        this.deviceTotal = result.data.total
        this.loading = false
      }
    },
    // 初始化关联列表
    async initLink() {
      const {data: result} = await this.$axios.get('/unitRelate/list', {params: {unitId: this.unitId}})
      if (result.code === 200) {
        this.linkList = result.data
      }
    },
    // 初始化未关联单位列表
    async initNotLink() {
      const data = {
        pageSize: 100,
        pageNo: this.notLinkPage,
        unitName: this.keyword
      }
      this.linkLoading = true
      const {data: result} = await this.$axios.get('/unit/listWeb', {params: data})
      if (result.code === 200) {
        this.notLinkList = result.data.result
        this.notLinkTotal = result.data.total
        this.drawUnit()
      }
    },
    // 删除某条数据
    async deleteCompany(id) {
      const confirm = await this.$confirm('此操作将永久删除该单位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.delete(`/unit/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 批量删除
    async deleteMuti() {
      const array = []
      for (let i = 0; i < this.deleteList.length; i++) {
        array.push(this.deleteList[i].id)
      }
      const confirm = await this.$confirm('此操作将永久删除这些单位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.delete(`/unit/delBatch/${array.join(',')}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - this.deleteList.length) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.clearSelection()
          this.initTable()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 移除已选联动单位
    delWillLink(tag) {
      this.unitListIds.splice(this.unitListIds.indexOf(tag), 1)
    },
    async delLink(id) {
      const confirm = await this.$confirm('此操作将永久删除该联动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const data = {
          unitId: this.unitId,
          id: id
        }
        const {data: result} = await this.$axios.delete('/unitRelate/remove', data)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initLink()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    updateCompany: _.debounce(function () {
      this.$refs.companyForm.validate(async valid => {
        if (valid) {
          if (this.companyForm.areaCodes.length > 0) {
            this.companyForm.areaCode = this.companyForm.areaCodes[this.companyForm.areaCodes.length - 1]
          }
          else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          if (this.companyForm.containSystems.length > 0) {
            this.companyForm.containSystem = this.companyForm.containSystems.join(',')
          }
          const data = this.companyForm.areaCodes
          delete this.companyForm.areaCodes
          const {data: result} = await this.$axios.put(`/unit/edit/${this.companyId}`, this.companyForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.unitShow = false
            this.initTable()
          }
          else {
            this.companyForm.areaCodes = data
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    // 新增单位
    addCompany: _.debounce(function () {
      this.$refs.companyForm.validate(async valid => {
        if (valid) {
          if (this.companyForm.areaCodes.length > 0) {
            this.companyForm.areaCode = this.companyForm.areaCodes[this.companyForm.areaCodes.length - 1]
          }
          else {
            this.$message.error('请选择该单位所在行政区域')
            return
          }
          if (this.companyForm.containSystems.length > 0) {
            this.companyForm.containSystem = this.companyForm.containSystems.join(',')
          }
          const data = this.companyForm.areaCodes
          delete this.companyForm.areaCodes
          const {data: result} = await this.$axios.post('/unit/add', this.companyForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.unitShow = false
            this.initTable()
          }
          else {
            this.companyForm.areaCodes = data
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    addWillLink(unitName, id) {
      const flag = _.some(this.unitListIds, {
        unitName: unitName,
        id: id
      })
      if (!flag) {
        this.unitListIds.push({
          unitName: unitName,
          id: id
        })
      }
      else {
        this.$message.error('不能添加相同的单位')
      }
    },
    async addLink() {
      const unitListIds = []
      for (let i = 0; i < this.unitListIds.length; i++) {
        unitListIds.push(this.unitListIds[i].id)
      }
      const {data: result} = await this.$axios.post('/unitRelate/add', {unitId: this.unitId, unitListIds: unitListIds})
      if (result.code === 200) {
        this.$message.success('添加成功')
        this.initLink()
        this.linkAddShow = false
      }
    },
    // 查询省平台单位
    async initProvince() {
      if (this.areaCode) {
        this.searchUnit.areaCode = this.areaCode
      }
      if (Object.prototype.hasOwnProperty.call(this.searchUnit, 'areaName')) {
        if (_.size(this.searchUnit) === 1) {
          this.$message.erro('无法单独根据行政区域查询')
          return
        }
      }
      this.provinceUnitLoading = true
      const {data: result} = await this.$axios.get('/unit/listFromProvince', {params: this.searchUnit})
      if (result.code === 200) {
        this.provinceUnitList = result.data
        this.provinceUnitLoading = false
      }
      else {
        this.provinceUnitLoading = false
      }
    },
    // 从省平台拉取单位
    async addProvinceUnit() {
      const {data: result} = await this.$axios.post('/unit/addFromProvince', this.provinceUnit)
      if (result.code === 200) {
        this.$message.success('新增成功')
        const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
        this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
        this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
        this.provinceUnitShow = false
        this.initTable()
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 初始化迁移设备单位
    async initTansfer() {
      const data = {}
      if (this.searchTransfer.unitName) {
        data.unitName = this.searchTransfer.unitName
      }
      if (this.transferAreaCodes.length > 0) {
        data.areaCode = this.transferAreaCodes[this.transferAreaCodes.length - 1]
      }
      data.pageNo = this.currentPage
      data.pageSize = 100
      this.transferLoading = true
      const {data: result} = await this.$axios.get('/unit/listWeb', {params: data})
      if (result.code === 200) {
        this.transferLoading = false
        this.transferUnitList = result.data.result
      }
      else {
        this.transferLoading = false
        this.$message.error(result.msg)
      }
    },
    // 迁移设备
    async transferDevice() {
      const confirm = await this.$confirm('该操作将会将该单位的设备迁移至选中的单位,请慎重操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post('/unit/unitDeviceMove', {
          unitId: this.companyId,
          willUnitId: this.transferUnitId
        })
        if (result.code === 200) {
          this.transferShow = false
          this.initTable()
          this.$message.success('迁移成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 选中区域
    async checkArea(data, node, row) {
      const {data: result} = await this.$axios.get('/areaJian/list', {params: {type: data.type + 1, parentId: data.id}})
      if (result.code === 200) {
        if (!_.isEqual(data.children, result.data)) {
          result.data.forEach(item => {
            data.children.push(item)
          })
        }
      }
    },
    // 选中省平台单位
    chooseProvinceUnit(val) {
      this.provinceUnit = val
      this.provinceBtnFlag = true
    },
    // 选中迁移单位
    chooseUnit(val) {
      this.transferUnitId = val.id
      this.transferBtnFlag = true
    },
    // 查看图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getUnitImage(res, file, fileList) {
      if (res.code === 200) {
        this.companyForm.unitImage = res.data
      }
    },
    error(err, file, fileList) {
      this.$message.error(err)
    },
    // 获取位置
    async getPosition() {
      console.log(this.location)
      this.companyForm.lng = this.location.lng
      this.companyForm.lat = this.location.lat
      this.mapShow = false
    },
    // 设定位置
    async setPosition(e) {
      if (this.optFlag2) {
        this.location.lng = e.point.lng
        this.location.lat = e.point.lat
      }
    },
    // 初始化坐标
    handler({BMap, map}) {
      const that = this
      if (this.optFlag2) {
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(function (r) {
          that.location.lng = r.point.lng
          that.location.lat = r.point.lat
        })
      }
    },
    // 初始化坐标
    handler2({BMap, map}) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.markerIcon = (imgUrl, num1, num2) => {
        return new BMap.Icon(imgUrl, new BMap.Size(num1, num2))
      }
      this.selfMarker = (pt, myIcon) => {
        return new BMap.Marker(pt, {icon: myIcon})
      }
      this.label = (name, num1, num2) => {
        return new BMap.Label(name, {offset: new BMap.Size(num1, num2)})
      }
      this.infoWindow = (content, opts) => {
        return new BMap.InfoWindow(content, opts)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
    },
    async open(flag, row) {
      switch (flag) {
        case 'add':
          this.unitShow = true
          this.$nextTick(() => {
            this.$refs.companyForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.sectorOptions = []
          this.companyForm = JSON.parse(JSON.stringify(row))
          this.sectorOptions.push({
            id: this.companyForm.fireManageId,
            companyName: this.companyForm.fireManageName
          })
          if (!this.companyForm.unitImage) {
          }
          else {
            this.fileList = [{name: 'file', url: this.companyForm.unitImage}]
          }

          if (!this.companyForm.containSystem) {
            this.$set(this.companyForm, 'containSystems', [])
          }
          else {
            this.$set(this.companyForm, 'containSystems', this.companyForm.containSystem.split(',').map(Number))
          }

          if (!this.companyForm.natureId) {
            this.$set(this.companyForm, 'natureId', '')
          }
          if (!this.companyForm.natureId) {
            this.$set(this.companyForm, 'natureId', '')
          }
          if (!this.companyForm.industryId) {
            this.$set(this.companyForm, 'industryId', '')
          }
          if (!this.companyForm.smallTypeId) {
            this.$set(this.companyForm, 'smallTypeId', '')
          }
          this.companyId = row.id
          this.optFlag = false
          this.unitShow = true
          break
        case 'map':
          this.mapShow = true
          this.optFlag2 = true
          break
        case 'location':
          this.mapShow = true
          this.optFlag2 = false
          this.location = row
          break
        case 'device':
          this.companyId = row
          this.initDevice()
          this.deviceShow = true
          break
        case '3D':
          this.threeDShow = true
          break
        case 'video':
          this.cameraCode = row.cameraCode
          this.videoShow = true
          break
        case 'position':
          this.location = {
            lng: row.lng,
            lat: row.lat
          }
          this.positionShow = true
          this.getWaterSourceList()
          break
        case 'floor':
          this.floorPic = this.floorPicList[row]
          this.floorTitle = row + 1 + '楼'
          this.floorShow = true
          break
        case 'unit':
          this.$refs.CompanyDetailDialog.show(row.id)
          // this.companyId = row.id
          // this.infoShow = true
          // this.$router.replace('/home/companyinfo/info/base')
          break
        case 'link':
          this.unitId = row
          this.initLink()
          this.linkShow = true
          break
        case 'addLink':
          this.initNotLink()
          this.linkAddShow = true
          break
        case 'province':
          this.provinceUnitShow = true
          this.getProvinceArea()
          break
        case 'area':
          this.areaShow = true
          break
        case 'transfer':
          this.companyId = row
          this.transferShow = true
          break
      }
    },
    // 查找设备类型
    async getDeviceTypeList() {
      const {data: result} = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.deviceTypeList = result.data
      }
    },
    // 获取类型列表
    async getTypeList() {
      const {data: result} = await this.$axios.get('/unit-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 获取小类型列表
    async getSmallTypeList() {
      const {data: result} = await this.$axios.get('/unit-small-type/list')
      if (result.code === 200) {
        this.smallTypeList = result.data
      }
    },
    // 获取单位性质列表
    async getNatureList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'unitNature'}})
      if (result.code === 200) {
        this.natureList = result.data
      }
    },
    // 获取监管等级列表
    async getIndustryList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'unitindustry'}})
      if (result.code === 200) {
        this.industryList = result.data
      }
    },
    // 获取行业主管部门
    async getIndustryDeptList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'industryDept'}})
      if (result.code === 200) {
        this.industryDeptList = result.data
      }
    },
    // 获取监管等级列表
    async getMonitorList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'monitorLevel'}})
      if (result.code === 200) {
        this.monitorList = result.data
      }
    },
    // 获取系统列表
    async getSystemList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'containSystem'}})
      if (result.code === 200) {
        this.systemList = result.data
      }
    },
    // 查找行政区域
    async getAreaList() {
      const {data: result} = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      }
      else {
        this.$message.error(result.msg)
      }
    },
    changeKey(array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    // 获取省平台区域
    async getProvinceArea() {
      const {data: result} = await this.$axios.get('/areaJian/list', {params: {type: 8}})
      if (result.code === 200) {
        this.provinceAreaList = result.data
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 选中区域
    changeArea() {
      const data = this.$refs.tree.getCurrentNode()
      if (data) {
        this.areaName = data.areaName
        this.areaCode = data.areaCode
        this.areaShow = false
      }
    },
    async getWaterSourceList() {
      const {data: result} = await this.$axios.get('/equipment/list', {params: {typeId: 15}})
      if (result.code === 200) {
        //  遍历所有的告警信息
        result.data.result.forEach((item, i) => {
          const point = this.point(item.lng, item.lat)
          const myIcon = this.markerIcon(require('../../assets/img/15.png'), 32, 32)
          const marker = this.selfMarker(point, myIcon)
          const label = this.label('室外水源', -16, -25)
          marker.setLabel(label)
          this.map.addOverlay(marker)
        })
      }
    },
    // 单位同步
    async setUnitSync(id) {
      const confirm = await this.$confirm('是否同步该单位至省平台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post(`/pUnit/add/${id}`)
        if (result.code === 200) {
          this.initTable()
          this.$message.success('同步成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 选中
    handleSelectionChange(val) {
      this.deleteList = val
    },
    // 导出Excel
    async exportExcel() {
      this.loading2 = true
      const data = {}
      Object.assign(data,this.searchForm)
      delete data.date
      if (this.searchForm.typeId || this.typeId) {
        data.typeId = this.searchForm.typeId || this.typeId
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      data.installDeviceStatus = this.searchForm.installDeviceStatus
      const result = await this.$axios.post('/util/exportUnit', data, {responseType: 'blob'})
      if (result.data.type === 'application/json') {
        this.loading2 = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.loading2 = false
      }
      else {
        this.loading2 = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], {type: 'application/octet-stream'})
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '单位信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
      else {
        navigator.msSaveBlob(blob, '单位信息.xlsx')
      }
    },
    /* 画出单位的 marker */
    drawUnit() {
      this.notLinkList.forEach((item, index) => {
        if (index === 0) {
          this.pointTo(item, 'localtion')
        }
        const point = this.point(item.lng, item.lat)
        const marker = this.marker(point)
        marker.type = 'unit'
        this.map.addOverlay(marker)
        this.linkLoading = false
        marker.addEventListener('click', (e) => {
          this.pointTo(item, 'localtion')
        })
      })
    },
    /* 信息框 */
    drawInfoWindow(item, point) {
      const imgUrl = require('../../assets/img/failed.png')
      let content = `<div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="display: flex;align-items: flex-start;justify-content: space-between;">`
      if (!item.unitImage) {
        content += `<div style="width: 100px;height:80px"><img src="${imgUrl}" width="100%" height="100%" alt="暂无图片"></div>`
      }
      else {
        content += `<div style="width: 100px;height:80px">
                    <img src="${item.unitImage}" style="width: 100%;height: 100%" />
                  </div>`
      }
      content += `<div style="padding-left: 10px">
                      <p>地址：${item.areaName}${item.address}</p>
                      <p>负责人：${item.legalName}</p>
                      <p>联系方式：${item.legalPhone}</p>
                      <p>安全等级：${item.monitorLevelName}</p>
                  </div>
              </div>
              <div style="display: flex;justify-content: flex-end;">
                  <input type="button" value="添加" onclick="return addWillLink('${item.unitName}',${item.id})" style="cursor: pointer;background-color: #409EFF;margin-top: 10px;padding: 5px 20px;outline:none;border:none;border-radius: 20px;color: #fff"/>
              </div>
            </div>`
      const infoWindow = this.infoWindow(content, {title: item.unitName})
      this.map.openInfoWindow(infoWindow, point)
    },
    async remoteMethod(query) {
      const {data: result} = await this.$axios.get('/fireManage/list', {params: {companyName: query}})
      if (result.code === 200) {
        this.sectorOptions = result.data
      }
    },
    // 定位
    pointTo(item, flag) {
      const point = this.point(item.lng, item.lat)
      this.map.centerAndZoom(point, 18)
      if (flag === 'localtion') {
        this.drawInfoWindow(item, point)
      }
    },
    // 关闭弹窗清空表单
    clear() {
      this.companyForm = JSON.parse(this.companyFormCopy)
      this.$refs.companyForm.clearValidate()
      this.$refs.upload.clearFiles()
    },
    // 清空表格选项
    clearSelection() {
      this.$refs.table.clearSelection()
      this.deleteList = []
    },
    close() {
      this.$router.push(this.path)
    },
    clearProvinceUnit() {
      this.searchUnit = {}
      this.provinceUnitList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}

.map {
  width: 100%;
  height: 500px !important;

  .bmView {
    height: 100% !important;

    > div:first-child {
      height: 500px !important;
    }
  }
}

.dialog {
  margin: 0;
  padding: 10px;

  ::v-deep .el-dialog__header {
    display: none !important;
  }
}

.demo-table-expand {
  font-size: 0;

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;

    ::v-deep label {
      color: #99a9bf !important;
    }
  }
}

::v-deep .el-table__expanded-cell {
  padding: 0 50px;
}

::v-deep .three3D-dialog {
  .el-dialog__body {
    padding: 0;

    .threeD-list {
      width: 100%;
      height: 400px;
      display: flex;

      .list-left {
        flex: 7;

        video {
          width: 100%;
          height: 100%;
        }
      }

      .list-right {
        flex: 3;
        overflow-y: scroll;
        border-left: 1px solid #ccc;

        .list-item {
          height: 40px;
          padding: 0 10px;
          border-bottom: 1px solid #ccc;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }

        .list-item:hover {
          background-color: #409eff;
          color: #fff;
        }
      }
    }

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}

.unit-info {
  ::v-deep .el-dialog__header {
    height: 24px;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    height: calc(100vh - 54px);

    .unit-header {
      background-color: rgba(67, 77, 120, 0.15);
      color: #39b8e6;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      height: 76px !important;

      .header-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        width: 100%;

        .el-menu {
          height: 100%;
          display: flex;
          justify-content: center;
          background-color: transparent !important;
          border-bottom: none !important;

          .el-menu-item,
          .el-submenu__title {
            height: 100%;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          //设置选中el-menu-item时的ff2556样式
          .el-menu-item .is-active,
          .el-submenu__title .is-active {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          //设置鼠标悬停时el-menu-item的样式
          .el-menu-item:hover,
          .el-submenu__title:hover {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          .el-menu-item:focus,
          .el-submenu__title:focus {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }
        }
      }
    }

    .unit-main {
      height: calc(100% - 76px);
      padding: 15px 15px 3% 15px;
      box-sizing: border-box;
    }
  }
}

.linkMap {
  height: 100%;

  ::v-deep .el-dialog__header {
    display: none !important;
  }

  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  i {
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }

  i:hover {
    color: #409eff;
  }

  .container_content {
    width: 100%;
    height: 800px;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: space-between;

    .left_box {
      flex: 1;
      padding: 10px;
      display: flex;
      overflow: hidden;
      border-right: 1px solid #ccc;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;

      .search_box {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;

        .title {
          text-align: left;
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 10px;
          color: #fff;
        }

        > div {
          flex: 4;

          ::v-deep .el-input__inner {
            background-color: rgba(7, 104, 159, 0.15);
            color: #fff;
          }
        }

        > div:last-child {
          flex: 5;
          margin-left: 5px;
        }
      }

      > div:nth-child(2) {
        flex: 10;
        padding: 10px 0;
        overflow: hidden;

        ::v-deep .el-table,
        .el-table__expanded-cell {
          background-color: transparent;

          tr,
          th {
            background-color: transparent;
            color: #fff;

            .el-table__expand-icon {
              color: #fff;
            }
          }

          .el-table--enable-row-hover,
          .hover-row > td,
          tr:hover > td {
            background-color: rgba(7, 104, 159, 0.15) !important;
          }

          .el-table__row--striped > td {
            background-color: rgba(7, 104, 159, 0.15) !important;
          }
        }
      }

      > div:last-child {
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;

        ::v-deep .el-pagination {
          margin-right: auto;
          color: #fff;
          background-color: transparent;

          .el-pagination__total,
          .el-pagination__jump {
            color: #fff;
          }

          .btn-prev,
          .btn-next {
            background-color: transparent;
            color: #fff;
          }

          .el-pager li {
            background-color: transparent !important;
          }

          .btn-quicknext {
            color: #fff;
          }
        }
      }
    }

    .right_box {
      flex: 3;
      position: relative;

      .bmView,
      .map_box {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .video {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 9999 !important;
        border: 1px solid #ccc;
        background-color: #f5f5f5;
      }

      .left_button {
        cursor: pointer;
        position: absolute;
        top: 370px;
        left: -1px;
        bottom: 370px;
        padding: 0 10px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        z-index: 10;
        border: 1px solid #ccc;
        border-left: none;
        align-items: center;
        justify-content: center;
        border-radius: 0 10px 10px 0;
        background-color: #fff;
      }

      .close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        font-weight: bold;
        padding: 5px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      }

      .link-tag {
        width: 85%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

        .link-title {
          font-size: 18px;
          color: #000;
          text-align: left;
          padding-bottom: 5px;
          border-bottom: 1px solid #ddd;
        }

        .tag-list {
          width: 100%;
          margin: 10px 0;

          .el-tag {
            margin-left: 10px;
          }

          .el-tag:first-child {
            margin-left: 0;
          }
        }

        .link-btn {
          border-top: 1px solid #ddd;
          padding-top: 5px;

          .el-button {
            float: right;
          }
        }
      }
    }
  }

  ::v-deep .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.5);
  }

  ::v-deep .el-pagination {
    padding: 0;
  }
}

.card-box {
  display: flex;
  flex-wrap: wrap;

  .el-card {
    width: 24%;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 20px;

    ::v-deep .el-card__header {
      padding: 10px;
    }

    ::v-deep .el-card__body {
      padding: 15px !important;

      div {
        display: flex;
        flex-direction: column;

        .el-tag {
          margin-bottom: 5px;
        }
      }
    }
  }

  .el-card:nth-child(4n) {
    margin-right: 0;
  }
}

.area {
  .el-button {
    background-color: rgba(15, 46, 75, 0.8);
    box-shadow: 0 0 10px #42affe inset;
  }
}

.el-tree {
  margin-top: 10px;
  background-color: transparent;
  color: #fff;

  ::v-deep .el-tree-node__content:hover {
    background-color: rgba(30, 74, 115, 0.5);
  }

  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: rgba(30, 74, 115, 0.5);
  }
}

.footer-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
